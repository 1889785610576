import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boas-vindas',
  templateUrl: './boas-vindas.component.html',
  styleUrls: ['./boas-vindas.component.css']
})
export class BoasVindasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
